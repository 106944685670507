<template>
	<div class="invite-index-page">

		<van-form @submit="onSubmit" class="content" ref="formItem" :show-error-message="false">

			<p style="text-align: center;font-size: 18px;padding: 10px 0 20px 0">{{translate('setting_login_password')}}</p>

			<van-row style="margin-bottom:15px;">
				<van-col span="24">
					<van-field
						clearable
						v-model="formItem.password"
						type="password"
						:placeholder="translate('please_enter_password')"
						:rules="[{ required: true, trigger: 'onSubmit', message: translate('please_fill_in_the_password') }]"
						style="border-radius: 24px!important;background: #f0f4ff"
					/>
				</van-col>
			</van-row>

			<van-row style="margin-bottom:25px;">
				<van-col span="24">
					<van-field
						clearable
						v-model="formItem.confirm_password"
						type="password"
						:placeholder="translate('please_enter_password_again')"
						:rules="[{ required: true, trigger: 'onSubmit', message: translate('please_fill_password_invite') }]"
						style="border-radius: 24px!important;background: #f0f4ff"
					/>
				</van-col>
			</van-row>

			<van-row>
				<van-col span="24">
					<van-button round block color="#FFAC00" native-type="submit">{{translate('confirm')}}</van-button>
				</van-col>
			</van-row>
	
		</van-form>
	</div>

</template>

<script>
	export default {
		name: 'invite_index',
		data() {
			return {
				load:false,
				formItem: {
					invite_id: "",
					password: "",
					confirm_password: "",
				},

				resourceURL: this.$axios.defaults.resourceURL,
				
			}
		},
		mounted() {
			if (this.$route.query.invite_id == "" || this.$route.query.invite_id == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.invite_id = this.$route.query.invite_id
			}
		},
		methods: {
			onSubmit() {
				if(this.load){
					return false
				}else{
					this.load=true
				}
				if (this.formItem.password != this.formItem.confirm_password) {
					this.$toast.fail(this.translate('passwords_entered_twice'))

					return false
				}

				this.$axios({
					method: 'post',
					url: 'invite/password',
					data: this.formItem
				}).then ((res) => {
					this.load=false;
					if (res.success) {
						this.$router.replace('/invite/success')
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			}
		},
	}
</script>